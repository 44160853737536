@import 'styles/tokens/colors';

.TextSize {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid $color-grey;
  border-radius: 8px;
  padding: 1px 0 1px 10px;
  width: 90px;
  color: $color-dark-grey;
  gap: 3px;
}

.TextSize__button {
  display: flex;
  flex-direction: column;
}

.TextSize__chevron {
  &:hover {
    color: $color-original-blue;
    cursor: pointer;
  }
}

.TextSize__chevron_down {
  transform: rotate(180deg);
}