@import 'styles/tokens/colors';
@import 'styles/tokens/transitions';
@import 'styles/media';
@import 'styles/typography';
@import 'styles/mixins';

.TestCase {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 12px 18px;
  width: 100%;
  background-color: #fff;
  row-gap: 12px;
}

.TestCase__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;

    .TestCase__iconWrapper {
      background-color: $color-original-blue;
    }

    .TestCase__icon {
      color: #fff;
    }
  }
}

.TestCase__header_disabled {
  &:hover {
    cursor: default;
  }
}

.TestCase__tagWrapper {
  display: flex;
}

.TestCase__tagWrapper_disabled {
  margin-right: 34px;
}

.TestCase__tag {
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.TestCase__tag_green {
  background-color: $color-green;
}

.TestCase__tag_red {
  background-color: $color-red;
}

.TestCase__iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background-color: $color-bg;
}

.TestCase__icon {
  transition: transform $transition-main;
}

.TestCase__icon_open {
  transform: rotate(-180deg);
}

.TestCase__text {
  @include typography-p3;
  @include scrollbar-small;

  overflow-x: auto;
  margin: 0;
  margin-top: 6px;
  border: 1px solid $color-light-grey;
  border-radius: 8px;
  padding: 8px 12px;
  color: $color-dark-grey;
}