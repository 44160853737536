@import 'styles/tokens/colors';
@import 'styles/typography';

.Answer {
  overflow-x: auto;
  border: 1px solid $color-grey;
  border-radius: 8px;
  padding: 14px 22px;
  color: $color-dark-grey;
}

.Answer_green {
  border: 1px solid $color-green;
  color: $color-black;
  background-color: $color-lighter-green;
}

.Answer_red {
  border: 1px solid $color-red;
  color: $color-black;
  background-color: $color-lighter-red;
}

.Answer__tag {
  margin-bottom: 10px;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.Answer__tag_green {
  background-color: $color-green;
}

.Answer__tag_red {
  background-color: $color-red;
}

.Answer__text {
  @include typography-p1;
}