@import 'styles/media';
@import 'styles/tokens/colors';
@import 'styles/typography';

.CodingPage__modalButtonBox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  gap: 28px;
}

.CodingPage__modalButton {
  width: 160px;
}
