@import 'styles/tokens/colors';

.Timeline {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 10px 12px;
  width: 100%;
  height: 60px;
  background-color: $color-bg-white-blue;
}

.Timeline_mobile {
  display: flex;
  column-gap: 12px;
  align-items: center;
}

.Timeline__iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background-color: $color-bg;

  &:hover {
    background-color: $color-original-blue;
    cursor: pointer;

    .Timeline__icon {
      color: #fff;
    }
  }
}

.Timeline__iconWrapper_disabled {
  &:hover {
    background-color: $color-bg;
    cursor: auto;
  }
}

.Timeline__icon_left {
  transform: rotate(90deg);
}

.Timeline__icon_right {
  transform: rotate(-90deg);
}

.Timeline__icon_disabled {
  color: $color-light-grey;
  cursor: auto;
}

.Timeline__box {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding: 10px 14px;
  height: 76px;
  background-color: $color-bg;
  row-gap: 12px;
}

.Timeline__lineWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;

  &:hover {
    cursor: pointer;
  }
}

.Timeline__line {
  position: relative;
  width: 100%;
  height: 2px;
  background-color: $color-grey;
}

.Timeline__point {
  position: absolute;
  top: -4px;
  border-radius: 2px;
  width: 10px;
  height: 10px;
  background-color: $color-dark-grey;

  &:hover {
    background-color: $color-original-blue;
  }
}

.Timeline__point_active {
  background-color: $color-original-blue;
}

.Timeline__point_blue {
  border-radius: 50%;
  background-color: $color-original-blue;
}

.Timeline__pointer {
  position: absolute;
  top: -20px;
}

.Timeline__button {
  border-color: #fff;
  color: $color-black;
}

.Timeline__button_padding {
  padding: 8px 12px;
}

.Timeline__boxTag {
  border-radius: 100px;
  padding: 4px 12px;
  color: #fff;
}

.Timeline__boxTag_green {
  background-color: $color-green;
}

.Timeline__boxTag_red {
  background-color: $color-red;
}