@import 'styles/media';
@import 'styles/tokens/colors';
@import 'styles/typography';

.Question {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 22px 16px;
  width: 100%;
  background-color: #fff;
  gap: 22px;

  @include media-up(breakpoint-md) {
    border: 1px solid $color-light-grey;
    border-radius: 20px;
    width: 750px;
  }

  @include media-up(breakpoint-sm) {
    padding: 24px 32px;
    gap: 32px;
  }
}

.Question__text_multiple {
  padding-top: 10px;
  color: $color-dark-grey;

  @include media-up(breakpoint-sm) {
    padding-top: 14px;
  }
}

.Question__answerList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Question__answer {
  display: flex;
  align-items: center;
  border: 1px solid $color-grey;
  border-radius: 8px;
  padding: 14px 22px;
  gap: 10px;

  &:hover {
    border-color: $color-original-blue;
    cursor: pointer;
  }
}

.Question__answer_selected {
  border-color: $color-original-blue;
  background-color: $color-bg;
}

.Question__buttonContainer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid $color-light-grey;
  padding: 14px 16px 10px;
  background-color: #fff;
  row-gap: 12px;

  @include media-up(breakpoint-sm) {
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    border-top: none;
    padding: 0;
  }
}

.Question__skipButton {
  color: $color-dark-grey;

  &:hover {
    cursor: pointer;
  }
}

.Question__button {
  width: 100%;

  @include media-up(breakpoint-sm) {
    width: 140px;
  }
}

.Question__description {
  overflow-x: auto;
  border-radius: 20px;
  padding: 14px 22px;
  width: 100%;
  background-color: $color-bg;

  pre {
    margin: 0;
    padding: 0;

    code {
      padding: 0;
    }
  }

  code {
    @include typography-p1;

    background-color: $color-bg;
  }
}

.Question__answerText {
  @include typography-p2;

  overflow-x: auto;

  pre {
    margin: 0;
    padding: 0;

    code {
      padding: 0;
    }
  }
}