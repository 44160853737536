@import 'styles/tokens/colors';

.QuestionBox {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 22px 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.QuestionBox_green {
  border: 1px solid $color-green;
  background-color: $color-lighter-green;

  &:hover {
    background-color: $color-light-green;
    cursor: pointer;
  }
}

.QuestionBox_red {
  border: 1px solid $color-red;
  background-color: $color-lighter-red;

  &:hover {
    background-color: $color-light-red;
    cursor: pointer;
  }
}

.QuestionBox__number {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  text-align: center;
  background-color: #fff;
}

.QuestionBox__wrapperTag {
  display: flex;
  column-gap: 8px;
}

.QuestionBox__tag {
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.QuestionBox__tag_green {
  background-color: $color-green;
}

.QuestionBox__tag_red {
  background-color: $color-red;
}

.QuestionBox__text {
  margin: 14px 0 0;
}