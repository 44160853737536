@import 'styles/media';
@import 'styles/tokens/colors';
@import 'styles/typography';
@import 'styles/tokens/_shadows';

.TitleTask {
  border-bottom: 1px solid $color-grey;
}

.TitleTask_borderHidden {
  border-bottom: none;
}

.TitleTask__titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 14px;

  @include media-up(breakpoint-sm) {
    padding-bottom: 20px;
  }
}

.TitleTask__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;

  @include media-up(breakpoint-sm) {
    column-gap: 20px;
    flex-direction: row;
    align-items: center;
  }
}

.TitleTask__titleMobile {
  margin-bottom: 10px;
}

.TitleTask__wrapperTags {
  display: flex;
  column-gap: 16px;
  flex-direction: column;
  padding-bottom: 16px;
  color: $color-dark-grey;
  row-gap: 10px;

  @include media-up(breakpoint-sm) {
    flex-direction: row;
    align-items: center;
    padding-bottom: 22px;
  }
}

.TitleTask__tags {
  display: flex;
  flex-flow: row wrap;
  gap: 6px;
}

.TitleTask__tag {
  border-radius: 6px;
  padding: 4px 12px;
}

.TitleTask__tag_blue {
  color: $color-original-blue;
  background-color: $color-light-blue;
}

.TitleTask__tag_green {
  color: $color-green;
  background-color: $color-light-green;
}

.TitleTask__tag_violet {
  color: #7f0ed8;
  background-color: #ecd5ff;
}

.TitleTask__circle {
  text {
    @include typography-h1;
  }
}

.TitleTask__circle_small {
  text {
    @include typography-h2;
  }
}

.TitleTask__time {
  text-align: end;
}

.TitleTask__netTime {
  display: flex;
  align-items: center;
  color: $color-dark-grey;
}

.TitleTask__netTimeIcon {
  margin: 0 6px;
  color: $color-grey;
  fill: $color-light-grey;
  cursor: pointer;

  &:hover {
    color: #fff;
    fill: $color-original-blue;
  }
}

.TitleTask__tooltip {
  border-radius: 20px;
  padding: 12px 18px;
  max-width: 340px;
  box-shadow: $shadow-intense;
  text-align: center;
  color: $color-dark-grey;
  background: #fff;
}
