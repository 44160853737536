@import 'styles/tokens/colors';

.TaskFinished {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 95vh;
  background-color: $color-bg;
  gap: 10px;
}