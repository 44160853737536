.select {
  &__control {
    border: 1px solid #d1d1d4 !important;
    border-radius: 8px !important;
    height: 40px !important;
    box-shadow: none !important;
    transition: all 0.2s ease-in-out !important;

    &:hover {
      cursor: pointer !important;
    }

    &--is-focused {
      border: 1px solid #d1d1d4 !important;
    }

    &--menu-is-open {
      .select__indicator {
        transform: rotate(180deg);
      }
    }
  }

  &__single-value {
    color: #1b1b1b !important;
  }

  &__indicator {
    color: #1b1b1b !important;
  }

  &__indicator-separator {
    display: none !important;
  }

  &__menu {
    border: 1px solid #d1d1d4 !important;
    border-radius: 8px !important;
    box-shadow: 0 0 20px rgb(4 3 53 / 5%) !important;
    background: #fff !important;

    &:hover {
      cursor: pointer !important;
    }
  }

  &__option {
    &:hover {
      cursor: pointer !important;
    }

    &--is-focused {
      color: #373af5 !important;
      background-color: #fff !important;
    }

    &--is-selected {
      color: #373af5 !important;
      background-color: #e5e5fe !important;
    }
  }
}