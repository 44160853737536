@import 'styles/tokens/colors';
@import 'styles/media';

.CodingContent {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $color-light-grey;
  height: 100%;
  background-color: #fff;

  @include media-up(breakpoint-lg) {
    flex-direction: row;
    height: calc(100vh - 135px);
  }
}

.CodingContent__code {
  position: relative;
  flex: 1;
}

.CodingContent__codeTitle {
  border-bottom: 1px solid $color-light-grey;
  padding: 9px 20px;
}

.CodingContent__slider {
  position: absolute;
  left: -10px;
  top: 50%;
  z-index: 1;
  color: $color-grey;
  fill: #fff;
  stroke: $color-grey;

  &:hover {
    color: $color-original-blue;
    stroke: $color-original-blue;
    cursor: w-resize;
  }
}

.CodingContent__slider_active {
  color: $color-original-blue;
  fill: $color-original-blue;
  stroke: #fff;

  &:hover {
    color: $color-original-blue;
    fill: $color-original-blue;
    stroke: #fff;
    cursor: w-resize;
  }
}