@import 'styles/tokens/colors';
@import 'styles/media';

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background-color: #fff;

  @include media-up(breakpoint-sm) {
    padding: 24px;
  }

  @include media-up(breakpoint-lg) {
    padding: 20px 40px;
  }
}

.Header__container {
  display: flex;
  align-items: center;
}

.Header__button {
  display: flex;
  column-gap: 6px;
  align-items: center;
  cursor: pointer;
}

.Header__buttonIcon {
  transform: rotate(90deg);
}

.Header__candidate {
  display: flex;
  align-items: center;
}

.Header__ellipse {
  margin-right: 12px;
  margin-left: 12px;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background-color: $color-black;
}