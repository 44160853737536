@import 'styles/tokens/colors';

.TimeCounter__title {
  display: flex;
  justify-content: space-between;
  color: $color-original-blue;
}

.TimeCounter__title_black {
  color: $color-black;
}

.TimeCounter__timeBox {
  display: flex;
  align-items: center;
  gap: 6px;
}

.TimeCounter__time {
  width: 72px;
  text-align: end;
}