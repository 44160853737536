@import 'styles/tokens/colors';
@import 'styles/media';

.TestCases {
  overflow-y: auto;
  border-radius: 8px;
  padding: 12px 18px;
  width: 100%;
  height: 460px;
  background-color: $color-bg;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  @include media-up(breakpoint-lg) {
    height: 600px;
  }
}

.TestCases__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.TestCases__tag {
  border-radius: 100px;
  padding: 4px 12px;
  color: #fff;
}

.TestCases__tag_green {
  background-color: $color-green;
}

.TestCases__tag_red {
  background-color: $color-red;
}

.TestCases__list {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90%;
  row-gap: 8px;
}

.TestCases__prompt {
  position: absolute;
  top: 50%;
  display: flex;
  flex-direction: column;
  color: $color-dark-grey;
  transform: translate(0, -50%);
  row-gap: 18px;
}