@import 'styles/media';
@import 'styles/tokens/colors';

.QuestionViewPage {
  padding: 0 16px;
  width: 100%;
  min-height: 100vh;
  background-color: $color-bg;

  @include media-up(breakpoint-sm) {
    padding: 0 24px;
  }

  @include media-up(breakpoint-lg) {
    padding: 0 40px;
  }
}
