@import 'styles/media';
@import 'styles/tokens/colors';
@import 'styles/typography';

.QuizPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid $color-light-grey;
  padding-top: 60px;
  width: 100%;
  min-height: calc(100vh + 96px);
  background-color: #fff;

  @include media-up(breakpoint-sm) {
    padding-top: 0;
    min-height: calc(100vh - 80px);
  }

  @include media-up(breakpoint-md) {
    padding-top: 40px;
    background-color: $color-bg;
  }
}

.QuizPage__modal {
  width: 100%;

  @include media-up(breakpoint-sm) {
    width: 546px;
  }
}

.QuizPage__modalContent {
  padding: 16px;

  @include media-up(breakpoint-sm) {
    padding: 20px 28px;
  }
}

.QuizPage__modalButtonBox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  gap: 28px;
}

.QuizPage__modalButton {
  width: 160px;
}

.QuizPage__header_fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $color-light-grey;
  padding: 6px 16px;
  background-color: #fff;
}

.QuizPage__spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #fff;
}