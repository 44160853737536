@import 'styles/tokens/_colors';
@import 'styles/tokens/_zIndex';
@import 'styles/tokens/_shadows';
@import 'styles/_typography';

.Tooltip {
  position: absolute;
  top: -80px;
  border-radius: 20px;
  padding: 12px 18px;
  width: 146px;
  height: 64px;
  box-shadow: $shadow-intense;
  color: $color-dark-grey;
  background: #fff;
  visibility: hidden;
}

.Tooltip__arrow {
  position: absolute;
  left: 50%;
  top: 100%;
  border-style: solid;
  border-width: 5px;
  border-color: #fff transparent transparent;
}
