@import 'styles/media';
@import 'styles/tokens/colors';

.CodingViewPage__content {
  display: flex;
  flex-direction: column;
  padding: 22px 16px;
  width: 100%;

  @include media-up(breakpoint-sm) {
    padding: 26px 24px;
  }

  @include media-up(breakpoint-lg) {
    padding: 20px 40px;
  }
}

.CodingViewPage__codeBox {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  gap: 16px;

  @include media-up(breakpoint-sm) {
    gap: 20px;
  }

  @include media-up(breakpoint-lg) {
    flex-direction: row;
  }
}
