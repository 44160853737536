@import 'styles/media';
@import 'styles/tokens/colors';
@import 'styles/typography';

.QuestionList {
  display: grid;
  justify-items: stretch;
  grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
  list-style: none;
  margin: 0;
  padding: 16px 0;
  gap: 12px;

  @include media-up(breakpoint-sm) {
    grid-template-columns: repeat(auto-fit, minmax(364px, 1fr));
    padding: 22px 0 78px;
    gap: 16px;
  }

  @include media-up(breakpoint-lg) {
    grid-template-columns: repeat(2, minmax(364px, 1fr));
    padding: 22px 0 18px;
    gap: 20px;
  }

  @include media-up(breakpoint-xl) {
    grid-template-columns: repeat(3, minmax(364px, 1fr));
  }
}

.QuestionList__item {
  min-height: 226px;
  max-width: 100%;

  @include media-up(breakpoint-lg) {
    min-height: 194px;
    max-width: 440px;
  }
}

.QuestionList__modalBody {
  left: 50%;
  top: 0;
  margin: 75px 0;
  max-width: 750px;
  transform: translate(-50%, 0);
}

.QuestionList__modalTitle {
  margin: 0;
  padding-bottom: 16px;
}

.QuestionList__modalIcon {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: $color-bg;
  cursor: pointer;
}

.QuestionList__modalContent {
  padding: 18px 16px;

  @include media-up(breakpoint-md) {
    padding: 20px 28px;
  }
}

.QuestionList__modalIcon_disabled {
  color: $color-light-grey;
  cursor: auto;
}

.QuestionList__modalIcon_left {
  transform: rotate(90deg);
}

.QuestionList__modalIcon_right {
  transform: rotate(-90deg);
}

.QuestionList__modalSlider {
  display: flex;
  column-gap: 12px;
  align-items: center;
}

.QuestionList__sliderText_green {
  color: $color-green;
}

.QuestionList__sliderText_red {
  color: $color-red;
}

.QuestionList__sliderText {
  min-width: 60px;
  text-align: center;
}

.QuestionList__questionTitle {
  padding: 22px 0 32px;
}

.QuestionList__questionText {
  overflow-x: auto;
  margin-bottom: 22px;
  border-radius: 20px;
  padding: 14px 22px;
  width: 100%;
  background-color: $color-bg;

  pre {
    margin: 0;
    padding: 0;

    code {
      padding: 0;
    }
  }

  code {
    @include typography-p1;

    background-color: $color-bg;
  }
}

.QuestionList__questionList {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  row-gap: 12px;
}

.QuestionList__selectContainer {
  @include typography-p2;

  border-radius: 8px;
  padding-top: 22px;
  width: 288px;

  @include media-up(breakpoint-sm) {
    width: 320px;
  }
}
