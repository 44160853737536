@import 'styles/tokens/colors';
@import 'styles/media';

.CodeEditorBox {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 12px 18px;
  width: 100%;
  height: 460px;
  background-color: $color-bg;

  @include media-up(breakpoint-lg) {
    height: 600px;
  }
}

.CodeEditorBox__codeWrapper {
  overflow: hidden;
  margin-top: 12px;
  border-radius: 8px;
  padding: 12px 18px 12px 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #fff;
}