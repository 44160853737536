@import 'styles/tokens/colors';
@import 'styles/tokens/transitions';
@import 'styles/media';
@import 'styles/typography';

.TaskDescription {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border-radius: 8px;
  padding: 12px 18px;
  width: 100%;
  background-color: $color-bg;
  row-gap: 12px;

  @include media-up(breakpoint-sm) {
    margin-top: 20px;
  }
}

.TaskDescription__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;

    .TaskDescription__iconWrapper {
      background-color: $color-original-blue;
    }

    .TaskDescription__icon {
      color: #fff;
    }
  }
}

.TaskDescription__iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background-color: #fff;
}

.TaskDescription__icon {
  transition: transform $transition-main;
}

.TaskDescription__icon_open {
  transform: rotate(-180deg);
}

.TaskDescription__text {
  @include typography-p2;

  margin-bottom: 6px;
  border-radius: 8px;
  padding: 14px 22px;
  color: $color-dark-grey;
  background-color: #fff;

  pre {
    white-space: pre-wrap;
  }
}
