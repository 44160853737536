@import 'styles/tokens/colors';
@import 'styles/typography';

.CodingFooter {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $color-light-grey;
  padding: 10px 40px;
  background-color: #fff;
}

.CodingFooter__switch {
  display: flex;
  align-items: center;
  gap: 12px;
}

.CodingFooter__switchLanguage {
  border: 1px solid $color-light-grey;
  border-radius: 8px;
  padding: 9px 16px;
  width: 128px;
  color: $color-light-grey;
}

.CodingFooter__switchTheme {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $color-light-grey;
  border-radius: 8px;
  padding: 8px;
  color: $color-dark-grey;

  &:hover {
    border-color: $color-original-blue;
    color: $color-original-blue;
    cursor: pointer;
  }
}

.CodingFooter__btns {
  display: flex;
  align-items: center;
  gap: 22px;
}

.CodingFooter__btn {
  min-width: 100px;
}

.CodingFooter__selectLanguage {
  @include typography-p3;

  width: 128px;
}