@import 'styles/tokens/colors';
@import 'styles/typography';
@import 'styles/media';

.CodingContentAside {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-right: 1px solid $color-light-grey;
  min-height: 100%;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.CodingContentAside__tabBar {
  position: fixed;
  display: flex;
  align-items: center;
  border-right: 1px solid $color-light-grey;
  border-bottom: 1px solid $color-light-grey;
  padding: 14px 40px 0;
  background-color: #fff;
  gap: 12px;
}

.CodingContentAside__tab {
  padding-bottom: 10px;
  color: $color-dark-grey;

  &:hover {
    border-bottom: 2px solid $color-original-blue;
    color: $color-original-blue;
    cursor: pointer;
  }
}

.CodingContentAside__tab_active {
  border-bottom: 2px solid $color-original-blue;
  color: $color-original-blue;
}

.CodingContentAside__text {
  @include typography-p2;

  flex: 1;
  margin-top: 50px;
  padding: 22px 20px 22px 40px;
  background-color: $color-bg;

  code {
    border: 1px solid $color-light-grey;
    border-radius: 6px;
    padding: 1px 8px;
    white-space: pre-line;
    color: $color-dark-blue;
    background-color: #fff;
  }
}

.CodingContentAside__examples {
  border-top: 1px solid $color-light-grey;
  border-bottom: 1px solid $color-light-grey;
  padding: 12px 40px;
  color: $color-dark-grey;
  background-color: #fff;
}

.CodingContentAside__example {
  margin-top: 8px;
  border-radius: 8px;
  padding: 14px 22px;
  background-color: #fff;
}

.CodingContentAside__console {
  flex: 1;
  margin-top: 50px;
  padding: 22px 20px 22px 40px;
  background-color: $color-bg;
}

.CodingContentAside__test {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
}

.CodingContentAside__testTag {
  border-radius: 100px;
  padding: 4px 12px;
  color: #fff;
}

.CodingContentAside__testTag_green {
  background-color: $color-green;
}

.CodingContentAside__testTag_red {
  background-color: $color-red;
}

.CodingContentAside__testCases {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 8px;
}

.CodingContentAside__spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}