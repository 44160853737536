@import 'styles/media';
@import 'styles/tokens/colors';

.ReportPage__spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.ReportPage__tasks {
  display: flex;
  column-gap: 12px;
  margin-top: 12px;
}

.ReportPage__tasks_border {
  border-bottom: 1px solid $color-grey;
}

.ReportPage__task {
  padding-bottom: 10px;
  width: 68px;
  color: $color-dark-grey;

  &:hover {
    cursor: pointer;
  }
}

.ReportPage__task_active {
  border-bottom: 2px solid $color-original-blue;
  color: $color-original-blue;
}

.ReportPage__title {
  padding: 26px 16px 0;
  background-color: $color-bg;

  @include media-up(breakpoint-sm) {
    padding: 40px 24px 0;
  }

  @include media-up(breakpoint-lg) {
    padding: 40px 40px 0;
  }
}

.ReportPage__title_border {
  border-bottom: 1px solid $color-light-grey;
}
