@import 'styles/tokens/colors';

.CodeLaunchPoint__tooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.CodeLaunchPoint__tooltip_red {
  color: $color-red;
}

.CodeLaunchPoint__tooltip_green {
  color: $color-green;
}

.CodeLaunchPoint__point {
  position: absolute;
  top: -4px;
  border-radius: 2px;
  width: 10px;
  height: 10px;
  background-color: $color-dark-grey;

  &:hover {
    background-color: $color-original-blue;
  }
}

.CodeLaunchPoint__point_active {
  background-color: $color-original-blue;
}